import React from 'react';
import styled from 'styled-components';
import {softWhite} from "@/styles/globalStyleVars";


const TickerItem = (data) => {

    return (
        <StyledTickerItem className={``}>
            <div className="d-flex">

                {
                    data?.data && data?.data?.length > 0 &&
                    data?.data?.map((e, index) => {

                        return (
                            <div key={index} className="ticker-single-item">
                                <img alt={'Maximus Education and Migration'} src={e?.full_path}/>
                            </div>
                        )
                    })
                }

            </div>
        </StyledTickerItem>
    )
};

const StyledTickerItem = styled.div`
    background: ${softWhite};
    //margin-right: 70px;
    .d-flex {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 70px;
        @media(max-width: 992px){
            gap: 30px;
        }
    }

    
    .ticker-single-item {
        width: 100%;
        min-width: 150px;
        img {
            height: 60px;
            position: relative !important;
            top: unset !important;
            left: unset !important;
            right: unset !important;
            bottom: unset !important;
        }
        
        @media(max-width: 992px){
            min-width: 100px;
        }
        @media(max-width: 767px){
            width: auto;
            min-width: 100px;
        }
    }


    @media(max-width: 992px){
     
    }

`;


export default TickerItem;
